import React from 'react';

import { Box, Typography } from '@mui/material';
import VideoPlayer from '../../layout/VideoPlayer';
import ButtonExternal from '../ButtonExternal';
import { isEmpty } from 'lodash';

export default function EventItemReverse({
	title,
	subtitle,
	text2,
	src,
	title2 = '',
	video = false,
	videoUrl = '',
	hasBtn = false,
	btnText = '',
	btnUrl = '',
	date = '',
	listItems = [],
	text,
	btn_center_under_text = false,
}) {
	return (
		<div className="md:my-4 my-2">
			<Box
				className={` items-start md:items-center md:space-x-10 space-x-0 flex flex-col-reverse md:flex-row gap-3 md:gap-4 my-2 md:my-4`}
			>
				<Box className="md:w-1/2 w-full">
					<Typography variant="h2" component="h2" color="secondary">
						{title}
					</Typography>
					{!!subtitle && (
						<Typography variant="h3" component="h3" color="secondary">
							{subtitle}
						</Typography>
					)}
					{!!title2 && (
						<Typography variant="h3" component="h3" color="secondary">
							{title2}
						</Typography>
					)}
					<Typography variant="subtitle2">{date}</Typography>

					<Typography variant="body1">{text}</Typography>
					{!!text2 && <Typography variant="body1">{text2}</Typography>}
					{listItems.length > 0 &&
						listItems.map((item) => (
							<Typography variant="body1" key={item}>
								{item}
							</Typography>
						))}
					{hasBtn && (
						<Box sx={{ textAlign: 'center', mt: 2 }}>
							<ButtonExternal href={btnUrl}>{btnText}</ButtonExternal>
						</Box>
					)}
				</Box>
				{src !== null && (
					<>
						{
							isEmpty(btnUrl) ?
								<Box className="md:w-1/2 w-full">
									<img
										alt=""
										className=" mx-auto object-contain w-full h-auto"
										loading="lazy"
										src={src}
									/>
								</Box> :

								<Box className="md:w-1/2 w-full">
									<a href={btnUrl} className='w-full mx-auto'>
										<img
											alt=""
											className="mx-auto object-contain w-full h-auto"
											loading="lazy"
											src={src}
										/>
									</a>
								</Box>
						}

					</>
				)}

				{video && (
					<Box className="md:w-1/2 w-full ">
						<VideoPlayer url={videoUrl} />
					</Box>
				)}
			</Box>
			{/* {hasBtn && !btn_center_under_text && (
				<Box sx={{ textAlign: 'center', mt: 2 }}>
					<ButtonExternal href={btnUrl}>{btnText}</ButtonExternal>
				</Box>
			)} */}
			{/* <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <ImagePopup src={curImage} />
      </CustomDialog> */}
		</div>
	);
}
